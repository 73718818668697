import { remainder } from "./math";
export function typedIncludes(arr, item) {
    return arr.includes(item);
}
export function enumerate(arr) {
    return arr.map((item, index) => [index, item]);
}
export function isShallowEqual(a, b) {
    if (a.length !== b.length)
        return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i])
            return false;
    }
    return true;
}
/**
 * Ponyfill for ES2023's findLastIndex.
 */
export function findLastIndex(arr, predicate) {
    for (let i = arr.length - 1; i >= 0; i--) {
        if (predicate(arr[i]))
            return i;
    }
    return -1;
}
export function groupBy(arr, key) {
    const result = new Map;
    for (const item of arr) {
        const k = key(item);
        if (result.get(k) === undefined)
            result.set(k, []);
        result.get(k).push(item);
    }
    return result;
}
export function range(startInclusive, endExclusive, step) {
    if (endExclusive === undefined) {
        endExclusive = startInclusive;
        startInclusive = 0;
    }
    if (step === undefined)
        step = 1;
    const result = [];
    for (let i = startInclusive; step > 0 ? (i < endExclusive) : (i > endExclusive); i += step) {
        result.push(i);
    }
    return result;
}
export function rotateLeft(arr, n) {
    const index = remainder(n, arr.length);
    return [...arr.slice(n), arr.slice(0, n)];
}
export function rotateRight(arr, n) {
    return rotateLeft(arr, -n);
}
export function shuffle(arr) {
    const result = [...arr];
    for (let i = result.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [result[i], result[j]] = [result[j], result[i]];
    }
    return result;
}
export function outerProduct(arr1, arr2) {
    return arr1.flatMap((item1) => arr2.map((item2) => [item1, item2]));
}
export function unique(arr) {
    return [...new Set(arr)];
}
